var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"detailPage"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('transition',{attrs:{"appear":"","enter-active-class":"animated slideInLeft","leave-active-class":"animated slideOutLeft"}},[_c('div',{class:_vm.showDetail === true
						? 'productDetailLeft col-2 showDetail'
						: 'productDetailLeft3 col-lg-6'},[(_vm.simulator == true)?_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.showDetail),expression:"showDetail"}],staticClass:"backLink",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.showDetail = !_vm.showDetail}}},[_c('arrowLeftIcon'),_vm._v(" "+_vm._s(_vm.$t("backlink"))+" ")],1):_vm._e(),_c('div',{staticClass:"containerImage "},[_c('img',{staticClass:"productImage",attrs:{"src":_vm.product.imgURL,"alt":_vm.product.name,"itemprop":"image"}})])])]),_c('transition',{attrs:{"appear":"","enter-active-class":"animated slideInRight ","leave-active-class":"animated slideOutRight "}},[_c('div',{staticClass:"productVariationList1 col-lg-6"},[_c('div',{staticClass:"nomeProduto"},[_vm._v(" "+_vm._s(_vm.product.name)+" ")]),_c('div',{staticClass:"descricaoProduto",domProps:{"innerHTML":_vm._s(_vm.product.description)}}),_c('div',{staticClass:"title_desc"},[_vm._v(_vm._s(_vm.$t("dimensoes"))+" (cm)")]),_c('div',{staticClass:"descricaoProduto",domProps:{"innerHTML":_vm._s(_vm.product.dimensoes)}}),_c('div',{staticClass:"title_desc"},[_vm._v(_vm._s(_vm.$t("acabamento")))]),_c('div',{staticClass:"descricaoProduto",domProps:{"innerHTML":_vm._s(_vm.product.acabamentos)}}),_c('div',{staticClass:"stock"},[_vm._v(_vm._s(_vm.$t("stock_disponivel")))]),(this.isLoggedIn==true )?_c('div',[_vm._l((_vm.category_name),function(category){return _c('router-link',{key:category.id,staticClass:" ",attrs:{"itemprop":"itemListElement","itemscope":"","itemtype":"http://schema.org/Product","to":{
								path:
								'/bloco-b/category/' +
								_vm.selectedCategory +
								'/collection/' +
								_vm.selectedCollection +
								'/id/' +
								_vm.selectedID +
								'/name/' +
								_vm.selectedName +
								'/type/' +
								category.id,
							}}},[_c('meta',{attrs:{"itemprop":"position","content":_vm.index}}),_c('a',{staticClass:"categorias_href",attrs:{"href":'/bloco-b/category/' +
										_vm.selectedCategory +
										'/collection/' +
										_vm.selectedCollection +
										'/id/' +
										_vm.selectedID +
										'/name/' +
										_vm.selectedName +
										'/type/' +
											category.id,"itemprop":"url"}},[_vm._v("— "+_vm._s(category.name))])])}),_c('br')],2):_c('div',_vm._l((_vm.category_name),function(category){return _c('div',{},[_c('div',{staticClass:"categorias_href",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.autentication()}}},[_vm._v("— "+_vm._s(category.name))])])}),0)])]),_c('transition',{attrs:{"appear":"","enter-active-class":"animated slideInUp  ","leave-active-class":"animated slideOutDown "}},[_c('div',{staticClass:"col-12"},_vm._l((_vm.imagens_detalhe),function(imagem){return _c('div',{staticClass:"imgContainerDetail"},[_c('img',{staticClass:"productDetailImage",attrs:{"src":imagem.imagem,"itemprop":"image"}})])}),0)])],1)]),_c('transition',{attrs:{"enter-active-class":"animated slideInRight faster","leave-active-class":"animated slideOutRight faster"}},[(_vm.showLoginForm)?_c('ModalLogin',{on:{"close":function($event){_vm.showLoginForm = false}}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }