<template>
	<div id="detailPage"  >
		<div class="container">
		<div class="row" >
			<transition
				appear
				enter-active-class="animated slideInLeft"
				leave-active-class="animated slideOutLeft"
			>
				<div
					:class="
					showDetail === true
						? 'productDetailLeft col-2 showDetail'
						: 'productDetailLeft3 col-lg-6'
					"
				>
					<!-- <p class="productName" itemprop="name">
						<mark>
							<span></span>
							{{ product.firstName }}
							<br />
							{{ product.secondName }}
						</mark>
					</p>
					<h1 class="pageTitle" v-show="!showDetail">
						{{ product.categoryNameSplit1
						}}
						<span>{{ product.categoryNameSplit2 }}</span>
					</h1> -->
					
					<a
						class="backLink"
						href="javascript:void(0)"
						@click="showDetail = !showDetail"
						v-if="simulator == true"
						v-show="showDetail"
					>
						<arrowLeftIcon />
						{{ $t("backlink") }}
					</a>
					
						
							<div class="containerImage ">
								<img :src="product.imgURL" class="productImage" :alt="product.name" itemprop="image" />
							</div>
						
					
				</div>
			
			</transition>
			

		<transition appear
			enter-active-class="animated slideInRight "
			leave-active-class="animated slideOutRight "
		>

			<div class="productVariationList1 col-lg-6" >
				
				<div class="nomeProduto">
					{{ product.name }}
				</div>
				<div class="descricaoProduto" v-html="product.description"></div>
				<div class="title_desc">{{ $t("dimensoes") }} (cm)</div>
				<div class="descricaoProduto" v-html="product.dimensoes"></div>
				<div class="title_desc">{{ $t("acabamento") }}</div>
				<div class="descricaoProduto" v-html="product.acabamentos"></div>
				<div class="stock">{{ $t("stock_disponivel") }}</div>
				 
				
				
				<div v-if="this.isLoggedIn==true "> <!-- ALTERAR PARA TRUE - LOGIN -->
					<router-link v-for="category in category_name" 
							class=" "
							itemprop="itemListElement"
							itemscope
							itemtype="http://schema.org/Product"							
							
							:to="{
								path:
								'/bloco-b/category/' +
								selectedCategory +
								'/collection/' +
								selectedCollection +
								'/id/' +
								selectedID +
								'/name/' +
								selectedName +
								'/type/' +
								category.id,
							}"
							:key="category.id"
							
						>
						
						<meta itemprop="position" :content="index"  />
							
							<a  class="categorias_href"
								:href="
									'/bloco-b/category/' +
										selectedCategory +
										'/collection/' +
										selectedCollection +
										'/id/' +
										selectedID +
										'/name/' +
										selectedName +
										'/type/' +
											category.id
									"
								itemprop="url"
							>&#8212; {{ category.name }}</a>
						</router-link><br>
				</div>
				<div v-else>
					<div class="" v-for="category in category_name">
						<div class="categorias_href" style="cursor:pointer" @click="autentication()">&#8212; {{ category.name }}</div>
					</div>
				</div>
					
				
			</div>
		</transition>

		<transition
			appear
			enter-active-class="animated slideInUp  "
			leave-active-class="animated slideOutDown " >
			<div class="col-12">
				<div class="imgContainerDetail" v-for="imagem in imagens_detalhe">
					<img :src="imagem.imagem" class="productDetailImage"  itemprop="image" />
				</div>
			</div>
		</transition>
</div>
</div>



		<!-- DESCOMENTAR LOGIN -->
		<transition
			enter-active-class="animated slideInRight faster"
			leave-active-class="animated slideOutRight faster"
		>
			<ModalLogin v-if="showLoginForm" @close="showLoginForm = false"></ModalLogin>
		</transition> 
	</div>
	
</template>

<script>
	import Simulate from "@/components/subcomponents/scroll.vue";
	import arrowLeftIcon from "@/components/ui/arrow-bold-left.vue";
	import arrowRightIcon from "@/components/ui/arrow-slim-right.vue";
	import ModalLogin from "@/components/subcomponents/ModalLogin";
	import { mapState, mapGetters } from "vuex";

	export default {
		name: "detailPage",
		components: {
			Simulate,
			arrowRightIcon,
			arrowLeftIcon,
			ModalLogin
		},
		computed:{
			...mapState(["login"]),
			...mapGetters(["isLoggedIn"])
		},
		data() {
			return {
				product: "",
				category_name: [],
				imagens_detalhe: [],
				fullVariants: [],
				variants: [],
				showDetail: "",
				simulator: "",
				currentPage: 0,
				itemsPerPage: 3,
				hasPaging: true,
				hasPrevLink: false,
				hasNextLink: false,
				totalPages: 0,
				sortedProducts: [],
				activeFilter: "",
				selectedCategory: null,
				selectedCollection: null,
				selectedID: null,
				selectedName: null,



				showModal: false,
				showLoginForm: false,
				redirectURL: null
			};
		},
		beforeMount() {
			if (this.$route.params) {
				this.selectedCategory = this.$route.params.category;
				this.selectedCollection = this.$route.params.collection;
				this.selectedID = this.$route.params.id;
				this.selectedName = this.$route.params.name;
			}
			
		},
		methods: {
			autentication() {
				if (!this.isLoggedIn) {
					this.showLoginForm = true;
					this.redirectURL = "/account";
				} else {
					this.$router.push("/account");
				}
			},
			prevPageClick() {
				this.currentPage--;
				this.changePage();
			},
			nextPageClick() {
				this.currentPage++;
				this.changePage();
			},
			changePage() {
				this.hasPrevLink = this.currentPage > 0;
				this.hasNextLink = this.currentPage < this.totalPages - 1;
				this.slice();
			},
			slice() {
				let slice = this.sortedProducts.slice(
					this.currentPage * this.itemsPerPage,
					(this.currentPage + 1) * this.itemsPerPage
				);
				this.variants = slice;
			},
			
		},
		watch: {
			isLoggedIn(neww, last) {
				console.log("isLoggedIn last: " + last + "to new: " + neww);

				if (!$cookies.isKey("userToken")) {
					console.log("userToken is null");
				} else {
					console.log("userToken");
				}
			}
		},
		mounted() {
			

			this.$http
				.get(
					"https://www.bstone.pt/webservices/" +
						this.$i18n.locale +
						"/products-detail2?id=" +
						this.$route.params.id
				)
				.then(response => {
					this.product = response.data.product;
					this.category_name = response.data.product.categoryName;
					this.imagens_detalhe = response.data.product.imagens_detalhe;
					// this.simulator = response.data.product.simulator;
					// this.showDetail = !this.simulator;
					// this.totalPages = Math.round(
					// 	this.product.variants.length / this.itemsPerPage
					// );
					// this.sortedProducts = this.product.variants;
					// this.currentPage = 0;

					// this.changePage();
				});
			
			
			this.$eventBus.$emit("pageFinishLoad", true);
		}
	};
</script>

<style lang="scss">
	#detailPage .modalLogin{
		z-index: 5;
	}
	body {
		margin: 0;
	}
	.productDetail3 #detailPage{
		margin-bottom: 250px;
	}

	@media (min-width: 1200px){
		.container-xl, .container-lg, .container-md, .container-sm, .productDetail3 .container {
			max-width: 1140px;
		}
	}
	.row{
		--bs-gutter-x: 1.5rem;
		--bs-gutter-y: 0;
		display: flex;
		flex-wrap: wrap;
		margin-top: calc(-1 * var(--bs-gutter-y));
		margin-right: calc(-.5 * var(--bs-gutter-x));
		margin-left: calc(-.5 * var(--bs-gutter-x));
	}
	.productDetail{
		padding-top: 90px;
	}
	.nomeProduto{
		font-family: "Oswald", sans-serif;
    	font-weight: 600;
		font-size: 55px;

		
	}
	.imgContainerDetail, .imgContainerDetail img{
		width: 100%;
		
	}

	#detailPage:has(.productDetailLeft3) {
			// width: 100%;
			height: 100%;
			padding-top: 90px;
			// position: fixed;
			// z-index: 2;
			// padding: 0;
			// -webkit-transition: all 0.5s ease 0.2s;
			// -moz-transition: all 0.5s ease 0.2s;
			// -o-transition: all 0.5s ease 0.2s;
			// transition: all 0.5s ease 0.2s;
	}

	.productDetailLeft3 {
			width: 100%;
			height: 100%;
			// position: fixed;
			// z-index: 2;
			// padding: 0;
			-webkit-transition: all 0.5s ease 0.2s;
			-moz-transition: all 0.5s ease 0.2s;
			-o-transition: all 0.5s ease 0.2s;
			transition: all 0.5s ease 0.2s;
	}
	.productDetailLeft3 img {
					// width: auto;
					width: 100%;
					padding-top: 90px;
					// padding-left: 150px;
					padding-right: 75px;
					// height: 100vh;
					-webkit-transition: all 0.2s ease;
					-moz-transition: all 0.2s ease;
					-o-transition: all 0.2s ease;
					transition: all 0.2s ease;
				}

	.stock{
		font-size: 18px;
		text-transform: uppercase;
		font-family: 'Oswald';
		margin-bottom: 10px;
		padding-top: 85px;
	}

	.categorias_href{
		display: block;
		color: #333;
		font-size: 22px;
		text-decoration: none;
		font-family: 'Oswald';
		letter-spacing: 3.96px;
		text-transform: uppercase;
		font-weight: normal;
	}
	.title_desc {
		padding-top: 15px;
    	padding-bottom: 10px;
		font-family: 'Ubuntu';
		font-weight: bold;
		font-size: 22px;
	}
	.descricaoProduto p{
		font-size: 22px;
		padding-top: 15px;
		
	}
	.imgContainerDetail{
		padding-top:15px;
	}

	#detailPage {
		height: 100vh;
		// padding-top: 90px;

		.productDetailLeft {
			width: 100%;
			height: 100%;
			position: fixed;
			z-index: 2;
			padding: 0;
			-webkit-transition: all 0.5s ease 0.2s;
			-moz-transition: all 0.5s ease 0.2s;
			-o-transition: all 0.5s ease 0.2s;
			transition: all 0.5s ease 0.2s;

			.productName {
				width: 270px;
				position: absolute;
				top: 200px;
				right: -20%;
				z-index: 2;

				font-family: "Noe Display", serif;
				font-weight: normal;
				font-size: 60px;
				line-height: 70px;
				color: #333;
				text-align: left;
				text-indent: 25px;

				-webkit-transition: all 0.5s ease;
				-moz-transition: all 0.5s ease;
				-o-transition: all 0.5s ease;
				transition: all 0.5s ease;

				span {
					border: 3px solid #333;
					width: 32px;
					display: block;
					content: "";
					position: absolute;
					top: 29px;
					left: -10px;
				}

				mark {
					background-color: white;
					padding: 2px 5px;
				}
			}

			.containerImage {
				height: 100%;
				overflow: hidden;
				z-index: 0;

				img {
					width: auto;
					height: 100vh;
					-webkit-transition: all 0.2s ease;
					-moz-transition: all 0.2s ease;
					-o-transition: all 0.2s ease;
					transition: all 0.2s ease;
				}
			}

			// &:hover .containerImage img,
			& .productName:hover > .containerImage img,
			& .pageTitle:hover > .containerImage img {
				cursor: pointer;

				-webkit-transform: scale(1.2);
				-moz-transform: scale(1.2);
				-o-transform: scale(1.2);
				transform: scale(1.2);
			}

			.pageTitle {
				z-index: 2;
				margin: 0 auto;
				left: 10%;
				bottom: 40px;
			}

			/*.backLink {
				position: fixed;
				bottom: 70px;
				right: 90%;
				width: 105px;
				z-index: 2;
				display: block;
				text-align: right;
				font-family: "Oswald", sans-serif;
				font-size: 18px;
				font-weight: 200;
				color: #fff;
				text-decoration: none;
				letter-spacing: 2px;

				&:before {
					position: absolute;
					top: 13px;
					right: 54px;
					content: "";
					width: 30px;
					height: 2px;
					background: #fff;
					-webkit-transition: width 0.2s ease;
					-moz-transition: width 0.2s ease;
					-o-transition: width 0.2s ease;
					transition: width 0.2s ease;
				}

				.arrowBoldIcon {
					transform: rotate(180deg);
					position: absolute;
					top: 3px;
					left: 20px;
					-webkit-transition: left 0.2s ease;
					-moz-transition: left 0.2s ease;
					-o-transition: left 0.2s ease;
					transition: left 0.2s ease;
				}

				&:hover {
					&:before {
						width: 50px;
					}
					.arrowBoldIcon {
						left: 0px;
					}
				}
			}*/
		}

		.productDetailLinks {
			height: 100%;
			padding: 35% 0 0;
			z-index: 2;
			position: absolute;
			display: block;
			top: 0;
			bottom: 0;
			right: 0;

			// a {
			// 	display: block;
			// 	// float: left;
			// 	// clear: left;
			// 	padding-bottom: 20px;

			// 	font-family: "Oswald", sans-serif;
			// 	font-size: 25px;
			// 	font-weight: 500;
			// 	color: #333;
			// 	text-decoration: none;
			// 	text-transform: uppercase;
			// 	letter-spacing: 4px;
			// 	padding-left: 220px;
			// 	position: relative;
			// 	will-change: color;
			// 	-webkit-transition: color 0.2s ease;
			// 	-moz-transition: color 0.2s ease;
			// 	-o-transition: color 0.2s ease;
			// 	transition: color 0.2s ease;

			// 	&:before {
			// 		position: absolute;
			// 		top: 20px;
			// 		left: 10px;
			// 		content: "";
			// 		width: 190px;
			// 		height: 1px;
			// 		background: #6a6a6a;
			// 	}

			// 	&:hover {
			// 		color: #c47c5a;
			// 	}
			// }
		}

		.variantsquantity {
			font-weight: 300;
			font-size: 15px;
			letter-spacing: 1px;
			color: #575757;
		}

		.productVariationList1 {
			// position: absolute;
			// top: 220px;
			padding-top: 90px;
			// right: 0;
			padding-bottom: 100px;

			-webkit-transition: all 0.5s ease 0.2s;
			-moz-transition: all 0.5s ease 0.2s;
			-o-transition: all 0.5s ease 0.2s;
			transition: all 0.5s ease 0.2s;

			.filters,
			.filtersMobile {
				background: #f0f0f0;
				height: 150px;
				position: fixed;
				padding: 90px 20px;
				top: 124px;
				left: 25%;
				z-index: 1;
				display: flex;
				align-items: center;
				justify-content: space-between;
				text-align: center;
				transition: all 0.1s ease;

				.order a,
				.pagination p {
					font-family: "Oswald", sans-serif;
					color: #333;
					text-decoration: none;
					text-transform: uppercase;
					letter-spacing: 1.5px;
					font-size: 15px;
					font-weight: 400;
					white-space: nowrap;
					margin: 0;
					position: relative;
				}

				.order a {
					padding: 0 20px;

					&::before {
						content: "";
						position: absolute;
						display: block;
						left: 20%;
						top: 11px;
						height: 1px;
						width: 0;
						background: #b7b7b7;
						will-change: width;
						-webkit-transition: width 0.2s ease;
						-moz-transition: width 0.2s ease;
						-o-transition: width 0.2s ease;
						transition: width 0.2s ease;
					}

					&::after {
						content: "";
						position: absolute;
						display: block;
						left: 0;
						top: 5px;
						height: 12px;
						width: 1px;
						background: #333;
					}

					&:first-child::after {
						display: none;
					}

					&:hover,
					&.active {
						color: #b7b7b7;

						&::before {
							width: 65%;
						}
					}
				}

				.pagination p {
					font-size: 20px;
					font-weight: 300;
					cursor: pointer;

					span.active {
						font-size: 24px;
						font-weight: 500;
					}
				}

				.pagination {
					display: flex;

					.arrowLeft,
					.arrowRight {
						width: 70px;
						height: 15px;
						display: block;
						position: relative;
						margin-top: 6px;

						.arrowSlimIcon {
							float: right;
						}

						&::before {
							position: absolute;
							top: 13px;
							right: 0;
							content: "";
							width: 40px;
							height: 1px;
							background: #333;
							will-change: width;
							-webkit-transition: width 0.2s ease;
							-moz-transition: width 0.2s ease;
							-o-transition: width 0.2s ease;
							transition: width 0.2s ease;
						}
					}

					.arrowLeft {
						.arrowSlimIcon {
							float: left;
							transform: rotate(180deg) translateY(-3px);
						}

						&::before {
							right: inherit;
							left: 0;
						}
					}

					&:hover {
						span.active {
							color: #c47c5a;
						}

						.arrowLeft::before,
						.arrowRight::before {
							width: 60px;
						}
					}
				}
			}

			.filtersMobile {
				display: none;
			}

			ul.variantTable {
				padding: 0;
				list-style: none;
				margin: 85px 0;

				li.variantItem {
					width: 100%;
					height: 180px;
					margin-bottom: 1px;
					background: #fff;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					will-change: transform;
					-webkit-transition: transform 0.2s ease;
					-moz-transition: transform 0.2s ease;
					-o-transition: transform 0.2s ease;
					transition: transform 0.2s ease;

					.img {
						width: 190px;
						height: 160px;
						margin-left: 10px;
						overflow: hidden;

						img {
							min-height: 100%;
							width: 100%;
							will-change: transform;
							-webkit-transition: transform 0.2s ease;
							-moz-transition: transform 0.2s ease;
							-o-transition: transform 0.2s ease;
							transition: transform 0.2s ease;
						}
					}

					&:hover {
						-webkit-transform: translateX(2%);
						-moz-transform: translateX(2%);
						-o-transform: translateX(2%);
						transform: translateX(2%);
						overflow: none;

						h2 {
							color: #c47c5a;
						}

						img {
							-webkit-transform: scale(1.1);
							-moz-transform: scale(1.1);
							-o-transform: scale(1.1);
							transform: scale(1.1);
						}
					}

					.info {
						height: 70px;
						width: 100%;
						display: flex;
						//justify-content: stretch;
						align-items: center;
						text-align: center;

						h2 {
							font-family: "Oswald", sans-serif;
							font-weight: 400;
							font-size: 28px;
							text-transform: uppercase;
						}

						div {
							height: 70px;
							border-left: 1px solid #b7b7b7;
							//display: flex;

							p {
								font-family: "Oswald", sans-serif;
								font-weight: 300;
								font-size: 21px;
								letter-spacing: 1px;
								color: #575757;

								span {
									text-transform: uppercase;
									font-size: 15px;
								}
							}
						}
					}
				}
			}
		}
	}
	
	@media (max-width: 812px) {
		.productDetailLeft3 img{
			padding-right: 0px !important;
		}


		#detailPage {
			.productDetailLeft {
				&.showDetail {
					//width: 100%;
					//z-index: 0;

					.productName {
						text-indent: 0;
						top: 67px;
						padding: 40px 0 30px;
						font-size: 38px;
						line-height: 43px;
						background-color: #f0f0f0;
						text-align: center;
						margin-right: 0;
						right: -83vw;
						width: 100vw;
						z-index: 1;

						mark {
							background-color: transparent;
						}
						span {
							display: none;
						}
					}

					.backLink {
						color: #333;
						bottom: auto;
						top: 130px;
						right: 80%;

						&:before {
							background: #333;
						}
						.arrowBoldIcon svg path {
							stroke: #333;
						}
					}
					.containerImage {
						display: none;
					}
				}

				.productName {
					text-align: center;
					right: -110%;
					margin-right: 40%;
				}

				.pageTitle {
					display: none;
				}
			}

			.productDetailLinks {
				padding: 100% 0 0;
				left: 25%;

				a {
					padding-left: 130px;

					&:before {
						width: 100px;
					}
				}
			}

			.productVariationList1 ul.variantTable li.variantItem {
				display: table;
				height: auto;
				width: auto;
				margin: 20px auto;
				padding: 30px;

				.img {
					margin: 0 auto;
					width: auto;
					height: auto;
				}
				.info {
					height: auto;
					width: auto;

					h2 {
						margin: 30px auto;
					}
					div {
						margin: 5px auto;
						border: 0;
					}
				}
			}

			.productVariationList1 {
				// top: 230px;

				.filters {
					left: 0;
					top: 210px;
					height: 80px;
					padding: 15px 0;

					.order {
						width: 100%;
					}
					.pagination {
						display: none;
					}
				}

				.filtersMobile {
					display: block;
					height: 30px;
					position: relative;
					top: 0;
					left: 0;
					padding: 0 15px 150px;
					justify-content: center;

					.pagination {
						justify-content: center;
					}
				}
			}
		}
	}

	@media (max-width: 1024px) {
		#detailPage .productDetailLeft .pageTitle {
			font-size: 8rem;
			line-height: 7rem;

			span {
				padding-left: 115px;

				&:before {
					width: 90px;
				}
			}
		}

		.productVariationList1 .filters {
			left: 155px;
		}
	}
	
</style>
